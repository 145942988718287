@import "../Global.module.scss";

.Dashboard {
	background: white;
	display: flex;
}

.Sidebar {
	width: 100%;
	max-width: 250px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-right: 0.5px solid #cccccc;
	box-shadow: 0px 3px 6px #00000029;
	padding-top: 30px;
	padding-bottom: 30px;
}

.Sidebar div {
	width: 128px;
	height: 128px;
	font: Bold 18px/29px Lato;
	letter-spacing: 0.09px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 30px 0 0;
}

.DashboardContent {
	flex: 1;
	width: 0;
	padding-left: 85px;
	position: relative;
}
