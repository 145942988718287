@import "../Global.module.scss";

.Card {
	margin: 135px auto;
	max-width: 487px;
	text-align: center;
	border: 1px solid #ccc;
	border-radius: 0px 0px 40px 40px;
	background: white;
	display: flex;
	flex-direction: column;
}

.CardContent {
	margin: 20px 40px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.WelcomeText {
	margin: 25px 0 13px;
	line-height: 1;
	font-weight: bold;
	font-size: 28px;
	// font: Bold 28px/9px Open Sans;
	letter-spacing: 0;
	color: #285a64;
	opacity: 1;
}
.SubText {
	font-weight: bold;
	color: #285a64;
	font-size: 24px;
}

.LoginForm,
.VerificationTool {
	margin: 45px 0 20px;
}

.LoginForm .TextInput {
	margin: 5px;
	width: 275px;
}

.ForgotPasswordText {
	text-align: center;
	font-size: 16px;
	font-weight: bold;
}

a,
.Link {
	color: #d39c3e;
	text-decoration: none;
	font-family: "Open Sans";
	font-weight: bold;
	cursor: pointer;
}

.ForgotPasswordInfo {
	text-align: center;
	margin: 80px 0 40px;
	font-size: 16px;
}

input[type="radio"] {
	position: relative;
	height: 15px;
	width: 15px;
	-webkit-appearance: none;
	background: transparent;
	outline: none;
	border: 1px solid white;
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
	transition: 0.5s;
	cursor: pointer;
}

input[type="radio"]:before {
	content: "";
	position: absolute;
	height: 12px;
	width: 12px;
	border-radius: 20px;
	top: 12%;
	left: 0;
	background: white;
	transform: scale(1.1);
	box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
	transition: 0.5s;
	border: 1px solid #cccccc;
}
input:checked[type="radio"]:before {
	left: 0px;
	background: #285a64;
}

.VerificationTool {
	display: flex;
	flex-direction: column;
	align-items: baseline;

	label {
		margin: 7px;
	}
	.TextInput {
		width: 275px;
	}
}
