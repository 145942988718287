.firstTitle {
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: bold;
	margin-top: 25px;
}

.container {
	width: 100%;
	flex-direction: column;
}
.Components {
	width: 487px;

	text-align: center;
	border: 1px solid #ccc;
	border-radius: 0px 0px 40px 40px;
	background-color: white;
}

.Head {
	display: block;
	height: 55px;
	background: #285a64;
	margin: 0px;
	padding: 0px;
}
.firstForm {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	width: 100%;
}
.singleInput {
	width: 85%;
}
.doubleInput {
	width: 85%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.secondForm {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	width: 100%;
}

.buttonNext {
	margin-top: 20px;
}

.circle {
	width: 40%;
	height: 13px;
	width: 13px;
	margin-top: 5px;
	border-radius: 50%;
	border: 1px solid #285a64;
	margin-right: 5px;
}

.arrowContainer {
	background-color: white;
	color: #d39c3e;
	width: 27px;
	height: 27px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	margin-right: 5px;
}
.buttonUpload {
	width: 134px;
	height: 39px;
	display: flex;
	justify-content: center;
	align-items: baseline;
}
.inputs {
	margin-top: 10px;
	color: black;
	width: 100%;
	outline: none;
	border: none;
	background-color: white;
	font: inherit;
	padding: 6px 10px;
	height: 33px;
	box-shadow: 0px 0px 4px 1px rgba(240, 240, 240, 1);
}
.inputs::placeholder {
	color: #ccc;
}

.ButtonContainer {
	position: absolute;
	bottom: 0px;
	padding-top: 30px;
	padding-bottom: 30px;
	width: 90%;
	display: flex;
	justify-content: flex-end;
}
