@import "../../Global.module.scss";

.LimitsAndSpreads {
	display: flex;
	margin-top: 30px;

	.TextInput {
		width: 131px;
		text-align: right;
		padding-right: 9px;
		box-sizing: border-box;
	}
}

.Card {
	width: 100%;
	max-width: 726px;
	min-height: 865px;
	margin-right: 30px;
	border: 0.5px solid #cccccc;
	border-radius: 0 0 38px 38px;
	display: flex;
	flex-direction: column;
}

.SubCard {
	max-width: 622px;
	margin: 35px 52px;
}

.SubHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 44px;
	background: #285a64 0% 0% no-repeat padding-box;
	border: 0.25px solid #cccccc;
	text-align: center;
	font: Bold 18px/11px Open Sans;
	color: #ffffff;
}

.SubHeader span {
	flex: 50;
}

.SubHeader i {
	padding: 10px;
}

.SubHeaderWithIcon {
	padding-left: 30px;
}

table {
	width: 90%;
	margin: 20px 30px;
	border-spacing: 0px;

	td {
		font: Regular 14px/20px Open Sans;
		height: 46px;
	}
}

.SpreadsTable {
	.Currency {
		width: 65%;
		text-align: center;
	}
}

.SpreadsTable,
.LimitsTable {
	th {
		font: Bold 18px/28px Open Sans;
	}
}

.LimitsTable td {
	text-align: center;
}

.LimitsTable td:nth-child(2) {
	text-align: left;
}

.LimitsTable td:nth-child(1) {
	width: 70%;
	font: Bold 18px/28px Open Sans;
}

.LimitsTable {
	tbody tr:nth-child(odd) {
		background: #c4d2d4;
	}
}

.Content {
	display: flex;
	align-items: baseline;
	margin: 32px 0 20px 30px;
}

.ColumnContent {
	@extend .Content;

	flex-direction: column;
}

.ColummContent {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	margin: 55px 0 20px 30px;
}

.SearchInput {
	width: 100%;
	max-width: 80%;
}

.PlusIcon {
	position: relative;
	top: 6px;
	margin-left: 6px;
	color: #d39c3e;
	cursor: pointer;
}

.editSpreadsExceptionsForm {
	table {
		width: -webkit-fill-available;
	}

	tr:not(:first-child) {
		td:nth-child(1) {
			width: 64%;
			text-align: left;
			padding-left: 15%;
		}

		tbody td:nth-child(2) {
			width: 34%;
			float: right;
		}
	}
}

button {
	float: right;
	margin-left: 15px;
}

.ActionButtons {
	align-self: flex-end;
	margin-right: 40px;
	position: relative;
	top: 254px;
}

.SearchContainer {
	min-height: 100px;
	max-height: 200px;
	overflow: auto;
	background: white;
	border: 1px solid #cccccc;
	border-top: none;
	position: absolute;
	top: 40px;
	width: 99.3%;
	float: left;
}

.searchBoxElement {
	margin-left: 15px;
	display: flex;
	flex-direction: flex-start;
	cursor: pointer;
	margin-top: 5px;
}
