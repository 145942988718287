.SearchEngine {
	// margin: 30px 0;
}

.SearchInput {
	border-left: none;
	border-top: none;
	border-right: none;
	border-bottom: 0.5px solid #cccccc;
	height: 35px;
	width: 100%;
	max-width: 593px;
	text-indent: 35px;
	font-size: 20px;
	font-family: Open Sans;
	color: #cccccc;
	// opacity: 0.3;
	&:focus {
		outline: none;
	}
	&::placeholder {
		opacity: 0.7;
	}
}

.SearchEngine i {
	position: absolute;
	color: #cccccc;
	margin: 11px 0 0 7px;
}
