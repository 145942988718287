.FourDotsContainer {
	display: flex;
	position: relative;
	cursor: pointer;
}
.FourDotsContainer i {
	color: #d39c3e;
}
.FourDotsContainer i:nth-child(1) {
	position: absolute;
	top: 0;
	left: 0;
}
.FourDotsContainer i:nth-child(2) {
	position: absolute;
	top: 0;
	right: 0;
}
.FourDotsContainer i:nth-child(3) {
	position: absolute;
	bottom: 0;
	left: 0;
}
.FourDotsContainer i:nth-child(4) {
	position: absolute;
	bottom: 0;
	right: 0;
}
