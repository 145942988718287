@import "../../Global.module.scss";

.Details {
	margin-top: 105px;
	.Title {
		font-size: 20px;
		margin-bottom: 5px;
	}
	.Row {
		font-size: 14px;
		margin: 3px 0;
	}
}

.order_pagination {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.numbers {
	margin-right: 5px;
	cursor: pointer;
	font-size: 15px;
	color: #d39c3e;
	font-weight: bold;
}

ul {
	list-style-type: none;
	display: flex;
}
