.Input {
	padding: 0px;
	display: block;
}

.Label {
	font-weight: bold;
	margin-bottom: 8px;
}

.InputElement {
	/* outline: none;
    border: none;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    width: 275px;
    height: 33px;
    box-shadow: 0px 0px 4px 1px rgba(240,240,240,1);   */
	color: #285a64;
	outline: none;
	border: 0.5px solid #cccccc;
	background-color: white;
	font: Regular 14px/9px Open Sans;
	padding: 0;
	text-indent: 9px;
	height: 33px;
	width: 275px;
	margin-bottom: 12px;
}

.InputElement:focus {
	outline: none;
	background-color: rgb(235, 235, 235);
}

.Invalid {
	border: 1px solid red;
	background-color: #fda49a;
}

.Invalid:focus {
	border: 1px solid red;
	background-color: #fda49a;
}

strong {
	color: #d39c3e;
	cursor: pointer;
}
