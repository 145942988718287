.App {
	background: url("./assets/images/Blank_map.svg") center (top / cover);
	width: 100%;
	min-height: 100vh;
}

.App,
p {
	color: #285a64;
	font-family: "Open Sans";
}
