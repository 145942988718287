@import "../../Global.module.scss";

////DetailsCard

.DetailsCard {
	position: relative;
	width: 100%;
	max-width: 842px;
	box-sizing: border-box;
	height: 686px;
	border-radius: 24px;
	margin-left: 5px;
	padding: 60px;
}

.Triangle {
	left: -30px;
	content: "";
	position: absolute;
	border-style: solid;
	border-width: 30px 30px 30px 0;
	border-color: transparent #ecf2ef transparent transparent;
}

.DetailsCard .Content {
	font-size: 14px;
	.Title {
		font: Bold 25px/20px Open Sans;
		border-bottom: 1px solid rgba($color: #285a64, $alpha: 0.3);
		padding-bottom: 10px;
	}

	.Section1 {
		display: flex;
		flex-direction: column;
		margin: 10px 0 40px;
		justify-content: space-evenly;
		height: 60px;
	}

	.Section2 {
		display: flex;
	}

	.Col1 {
		flex: 1 1 30%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.Col2 {
		flex: 2;
		margin-left: 45px;
	}

	.SubTitle {
		border-bottom: 1px solid rgba($color: #285a64, $alpha: 0.3);
		padding-bottom: 7px;
		margin-bottom: 10px;
	}

	.UserInfo {
		display: flex;
		margin-top: 50px;

		.Col1 {
			display: flex;
			flex-direction: column;
			height: 300px;
			justify-content: space-between;
		}

		.Col2 {
			display: flex;
			flex-direction: column;
			height: 300px;
			justify-content: flex-end;
		}
	}

	button {
		margin-left: 0px;
	}
}
