@import "../../Global.module.scss";
@import "./SearchInput.module.scss";

.Container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	background: white;
}
.BackgroundModal {
	position: relative;
	top: 99px;
	width: 100%;
	max-width: 1534px;
	height: 1129px;
	border: 0.5px solid #cccccc;
	border-radius: 0 0 38px 38px;
}

.ColoredDiv {
	height: 66px;
	background: #285a64;
	opacity: 0.3;
}

.UserManagement {
	width: 100%;
	max-width: 767px;
	padding-bottom: 30px;
	position: absolute;
	top: 33px;
	background: white;
	border: 0.5px solid #cccccc;
	border-radius: 0 0 38px 38px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Header {
	padding: 18px;
	height: 30px;
	width: 95.3%;
	background: #285a64;
	color: white;
	font: Bold 25px/29px Lato;
	display: flex;
	align-items: baseline;

	a {
		margin-left: auto;
	}
}

.Nav {
	margin: 20px 0;
	color: #d39c3e;
	font-size: 13px;

	i {
		margin: 0 5px;
	}
}

.SubHeader {
	width: 636px;
	height: 54px;
	background: #285a64;
	color: white;
	font: Bold 24px/9px Open Sans;
	text-align: center;
	vertical-align: middle;
	line-height: 54px;
}

.AddUser {
	margin-top: 23px;

	.Email {
		display: flex;
		flex-direction: column;
	}

	.UserInfo {
		width: 466px;
		border: 0.5px solid #cccccc;
		border-radius: 24px;
		padding: 31px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.Role {
		width: 100%;
		max-width: 347px;
	}

	.PersonalInfo {
		margin: 10px 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;

		input {
			margin: 0 10px 10px 0;
		}
	}

	.ChangePassword {
		width: 466px;
		height: 270px;
		border: 0.5px solid #cccccc;
		border-radius: 24px;
		display: flex;
		flex-direction: column;
		// align-items: baseline;
		padding: 31px;
		margin-top: 21px;
	}
}

.ActionButton {
	margin: 20px;
	display: flex;
	justify-content: center;
}

.AllUsers {
	width: 100%;
	max-width: 528px;
}

.UserLogInfo {
	width: 532px;
	height: 885px;
	border: 0.5px solid #cccccc;
	border-radius: 24px;
	margin-top: 25px;
	padding: 31px;
	box-sizing: border-box;

	.CloseIcon {
		float: right;
		position: relative;
		bottom: 17px;
		left: 17px;
		color: #d39c3e;
		font-size: 25px;
	}

	.UserInfo {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.Title {
		margin: 20px 0;
	}

	.Item {
		margin: 4px 0;
	}
}

.Message {
	height: 43px;
	color: #b72a2a;
	vertical-align: middle;
	line-height: 53px;
}

.AllRoles {
	width: 100%;
	max-width: 528px;
	margin-top: 23px;

	.RoleCard {
		width: 100%;
		max-width: 528px;
		min-height: 77px;
		border: 0.5px solid #cccccc;
		margin-bottom: 20px;
		padding: 26px;
		box-sizing: border-box;
		border-radius: 24px;
	}

	.Role {
		display: flex;
		align-items: center;
		font: Bold 24px/9px Open Sans;
	}

	.SubText {
		text-align: center;
		font-size: 12px;
	}

	.TextDetails {
		@extend .SubText;
		margin: 10px 0;
		white-space: pre-wrap;
	}

	.RoleDetails {
		display: flex;
		justify-content: center;
	}
}

.Label {
	font: bold 20px / 9px "Open Sans";
	margin-bottom: 17px;
	text-align: center;
}

.AddRole {
	.RoleName {
		width: 528px;
		height: 139px;
		border: 0.5px solid #cccccc;
		border-radius: 24px;
		padding: 31px;
		margin-bottom: 21px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
	}
}

.Permissions {
	width: 528px;
	border: 0.5px solid #cccccc;
	border-radius: 24px;
	padding: 31px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.PermissionList {
	margin-top: 30px;
	width: 100%;
	max-width: 345px;
}

.Permission {
	margin-bottom: 25px;
}

.ToggleIcon {
	float: right;
	color: #d39c3e;
}

.LowOpacityIcon {
	@extend .ToggleIcon;
	opacity: 0.3;
}

.UsersWithRole {
	width: 528px;
	height: 180px;
	border: 0.5px solid #cccccc;
	border-radius: 24px;
	padding: 31px;
	margin-top: 21px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	// align-items: center;
	box-sizing: border-box;
}

//STAConfirmationPopup

.STAConfirmationPopup {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;

	.Container {
		height: 300px;
		width: 100%;
		max-width: 674px;
		padding: 45px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #cccccc;
		border-radius: 38px;
	}

	.Title {
		font: Bold 35px/15px Open Sans;
		margin-bottom: 30px;
	}
}
