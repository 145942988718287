@import "../../Global.module.scss";

.Details {
	margin-top: 105px;
	height: 220px;
	width: 315px;
	.Title {
		font-size: 20px;
		margin-bottom: 5px;
		display: flex;
	}
	.Row {
		font-size: 14px;
		margin: 3px 0;
	}

	.CalendarIcon {
		color: #d39c3e;
		float: right;
		cursor: pointer;
		padding-right: 18px;
	}
}

.EmptyCard {
	height: 89px;
	padding: 15px 37px 13px 10px;
	margin-bottom: 10px;
}

.FirstRow {
	font-size: 12px;
	display: flex;
	align-items: center;
}

.Icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 27px;
	height: 27px;
	border-radius: 50%;
	color: white;
	font: Bold 16px/9px Open Sans;
	margin-right: 5px;
}

.PurchaseIcon {
	@extend .Icon;
	background: #285a64 0% 0% no-repeat padding-box;
}

.SalesIcon {
	@extend .Icon;
	background: #bfd2c7 0% 0% no-repeat padding-box;
}

.CI_Icon {
	@extend .Icon;
	border: 1px solid #9a2020;
	color: #9a2020;
}

.PaidCIIcon {
	@extend .Icon;
	color: #9a2020;
	border: 1px solid #9a2020;
	background: white 0% 0% no-repeat padding-box;
}

.BankIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 18px;
	height: 18px;
	margin-right: 5px;
}

.UnpaidCIIcon {
	@extend .Icon;
	color: #d39c3e;
	border: 1px solid #d39c3e;
	background: white 0% 0% no-repeat padding-box;
}

.ChartsItemTitle {
	font: Bold 20px/15px Open Sans;
	display: flex;
	align-items: center;
	margin: 20px 0 20px 20px;
}

.ChartsItem {
	// margin: 15px 0;
}

.BankChart {
	position: relative;
	left: -13px;
}

.ChartsPopup {
	position: fixed;
	top: 86px;
	left: 195px;
	width: 1410px;
	height: 226px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #cccccc;
	border-radius: 38px;
	padding: 45px;
	display: flex;
	justify-content: space-between;

	.CompressArrowsIcon {
		float: right;
		position: relative;
		bottom: 25px;
		left: 15px;
	}
}

.DollarSignIcon {
	color: #d39c3e;
	font-size: 10px;
	margin-right: 5px;
	cursor: pointer;
	margin-left: auto;
}

.BankTransfers {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;

	.Container {
		height: 295px;
		width: 100%;
		max-width: 1160px;
		padding: 45px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #cccccc;
		border-radius: 38px;
	}

	.Title {
		font: Bold 35px/15px Open Sans;
	}
}
.TransfersContainer {
	display: flex;
	justify-content: space-between;
	margin-top: 40px;

	.ItemTitle {
		font: Bold 20px/15px Open Sans;
		display: flex;
		align-items: center;
		margin: 20px 0;
	}
}

.TransfersTable {
	display: flex;
	flex-wrap: wrap;
	width: 120px;
	justify-content: space-between;

	div {
		margin: 5px 5px;
		flex: 1 41%;
	}
}

///////Multiple Card

.MultipleCard {
	display: flex;
	height: 115px;
	border: 0.5px solid #cccccc;
	border-radius: 0 24px 24px 0;
	margin-bottom: 10px;
	padding-top: 1px;
}

.Content {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	font-weight: bold;
	padding: 15px 37px 13px 10px;
	box-sizing: border-box;
}

.Card {
	height: 115px;
	min-width: 26px;
	max-width: 26px;
	background: #285a64;
	margin-right: 5px;
}

.OpenCard {
	height: 115px;
	width: 400px;
	padding: 15px 10px 13px 10px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	font-weight: bold;
}

.SingleCard {
	@extend .Content;
	border-left: 26px solid #285a64;
}

.Pagination {
	position: absolute;
	bottom: -660px;
	right: 371px;
}

.TradingButton {
	margin-left: 0px;
	border-radius: 10px;
	background: #d39c3e;
	border: none;
	color: white;
	font-size: 17px;
	font-weight: bold;
	padding: 5px;
	margin-top: 15px;
}
