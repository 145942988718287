body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* SCROLLBAR */
/* width */
::-webkit-scrollbar {
	width: 10px;
	background: none;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #cccccc;
	border-radius: 25px;
	background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(189, 189, 189, 0.555);
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(185, 185, 185, 0.61);
}
