@import "../../Global.module.scss";

.DocItem {
	font-size: 18px;
	margin-top: 13px;
}

.UploadButtons {
	display: flex;
	margin: 50px 0;
	justify-content: space-between;

	.box {
		// width: 212px;
		height: 58px;
		border: 0.5px solid #cccccc;
		border-radius: 38px;
		padding: 17px;
		text-align: center;
		font: Bold 18px/29px Lato;
		box-sizing: border-box;
	}

	span {
		margin: 0 10px 0 5px;
	}

	i {
		color: #d39c3e;
	}
}
