@import "../../../../Global.module.scss";
@import "../../Modals.module.scss";

.FilterModal {
	top: 54px;
	width: 341px;
	left: -303px;
	&::before {
		top: -30px;
		left: 281px;
	}
	margin-bottom: 20px;
	font-weight: 500;
	height: 475px;
}

.FilterModalBody {
	display: flex;
	flex-flow: column;
	// justify-content: center;
	padding: 20px 40px 40px;
	height: 365px;
}

.CustomInput {
	color: #285a64;
	outline: none;
	border: 0.5px solid #cccccc;
	background-color: white;
	font-size: 14px;
	text-indent: 9px;
	height: 33px;
	width: 130px;
	text-align: right;
	padding-right: 10px;
	box-sizing: border-box;
	margin-right: 5px;
}
