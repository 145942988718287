.NavigationItems {
	margin: 0px;
	padding: 0px;
	/* height: 51px; */
}
/* 
.Language{
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 15px;
    color:white;
} */

.NavigationItems ul {
	margin: 0px;
	padding: 0px;
}

.NavigationItems li {
	display: inline-block;
	font-weight: bold;
	/* position: absolute; */
	/* top: 0px;
    right:  0px; */
}

.NavigationItems a {
	color: white;
	display: block;
	padding: 15px;
	text-decoration: none;
}

.NavigationItems a:hover {
	text-decoration: none;
}
