.react-calendar {
	width: 350px;
	max-width: 100%;
	background: white;
	border: 1px solid #00000029;
	font-family: Arial, Helvetica, sans-serif;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.react-calendar button {
	margin: 0;
	border: 0;
	outline: none;
}

.react-calendar button:enabled:hover {
	cursor: pointer;
}

.react-calendar__navigation {
	height: 44px;
	margin-bottom: 1em;
	background-color: #285a64;
	color: #ffff;
}

.react-calendar__navigation button {
	min-width: 44px;
	background: none;
	color: white;
	font-weight: bold;
}

.react-calendar__navigation button[disabled] {
	background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
	padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
	font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.75em;
	padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
	color: #285a64;
}
.react-calendar__month-view__days__day--neighboringMonth {
	color: #285a64;
}

.react-calendar__month-view__days__day {
	color: #285a64;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
	padding: 2em 0.5em;
}
.react-calendar__tile {
	max-width: 100%;
	text-align: center;
	padding: 0.75em 0.5em;
	background: none;
}
.react-calendar__tile:disabled {
	background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: #e6e6e6;
}
.react-calendar__tile--hasActive {
	background: #285a64;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
	background: #285a64;
}
.react-calendar__tile--active {
	background: #285a64;
	color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: #285a64;
}
.react-calendar--selectRange .react-calendar__tile--hover {
	background-color: #e6e6e6;
}

.div.react-calendar__navigation__prev2-button {
	display: none;
}
