.select-box--box {
	width: 90%;
	position: relative;
	margin: 0 auto;
}
.select-box--selected-item {
	display: inline-block;
	height: 100%;
	/* width: 100%; */
	padding-top: 8px;
	padding-right: 40px;
	vertical-align: middle;
}

.select-box--container {
	color: #285a64;
	width: 100%;
	outline: none;
	/*border: none;*/
	border: 0.5px solid #cccccc;
	background-color: white;
	font: inherit;
	height: 33px;
	box-shadow: 0px 0px 4px 1px #f0f0f0;
	text-align: end;
	font-size: 12px;
}
.select-box--items div {
	background: white;
	width: 100%;
	padding-right: 25px;
	text-align: left;
	padding: 0 10px 6px;
}
.select-box--items {
	width: 100%;
	color: #285a64;
	font-size: 12px;
	z-index: 10;
	position: absolute;
	overflow: hidden;
	max-height: 122px;
	overflow-y: visible;
}
.select-box--arrow {
	width: 25px;
	height: 25px;
	margin: 0;
	padding: 0;
	top: 11%;
	right: 3px;
	display: inline-block;
	background-color: #d39c3e;
	position: absolute;
	border-radius: 25%;
}

.select-box--arrow-up {
	position: absolute;
	top: 9px;
	left: 5px;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 10px solid #fff;
}

.multiselect_div li:hover {
	background-color: #285a64 !important;
}

/* Track */
::-webkit-scrollbar-track {
	background: white;
}
