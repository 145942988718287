.Toolbar:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}

.Toolbar {
	display: inline-block;
	background: #285a64;
	margin: 0px;
	padding: 0px;
	width: 100%;
}

.RightNav {
	float: left;
	display: flex;
	flex-direction: row;
	align-items: center;
	/* padding: 10px;
    margin: 0px;
    padding: 0px; */
}

.NavigationItems {
	float: right;
	margin: 0px;
	padding: 0px;
}

.ProfileSettings {
	display: flex;
	flex: 1;
	justify-content: flex-end;
	align-content: center;
	align-items: center;
	margin-right: 30px;
	height: 54px;
}

.IconColor {
	color: white;
}

.ClientRegistry {
	cursor: pointer;
}
