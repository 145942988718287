@import "../../Global.module.scss";

.SelectCircle {
	position: absolute;
	margin-left: -46px;
	margin-top: 36px;
	width: 29px;
	height: 29px;
	border-radius: 50%;
	border: 1px solid #cccccc;
	cursor: pointer;
}
.SelectCircle.selected {
	background: #285a64;
}
.DetailsActionCard {
	margin-top: 100px;
	margin-left: 20px;
}
.FirstRow {
	display: flex;
	align-items: center;
	line-height: 26px;
}
.BankRow {
	margin-left: 50px;
	margin-top: 20px;
}
.BankRowHead {
	img {
		height: 18px;
		width: 18px;
	}
	span {
		position: relative;
		text-align: left;
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 0px;
		color: #285a64;
	}
}
.BankRowBody {
	margin-left: 40px;
	margin-top: 10px;
	span {
		font-size: 15px;
		font-weight: bold;
		margin-right: 20px;
		display: inline-block;
	}
	.BankRowBodyCurrency {
		width: 30px;
	}
	.BankRowBodyValue {
		width: 100px;
	}
}
.graphsContainer {
	width: 500px;
	margin-right: 100px;
	height: 1000px;
	overflow-y: scroll;
	overflow-x: hidden;
	flex: 10 0 auto;
}
.graphBankContainer {
	width: 280px;
	margin-left: auto;
	margin-right: auto;
}
.graphBankRow {
	display: flex;
	align-items: center;
	width: 280px;
	i {
		color: #d39c3e;
	}
}
.graphBankName {
	font-size: 20px;
	font-weight: bold;
	margin-left: 8px;
	flex: 1;
	white-space: nowrap;
}
.graphNrRow {
	position: relative;
	display: flex;
	border-bottom: solid 0.5px #285a64;
	left: 35px;
	margin-top: 10px;
	height: 30px;
}
.graphNr {
	flex: 1;
	position: relative;
	left: -5px;
}
.graphNr::after {
	content: "";
	position: absolute;
	width: 1px;
	height: 5px;
	background: #285a64;
	left: 5px;
	bottom: 0;
}
.graphLineContainer {
	padding-top: 20px;
	padding-bottom: 20px;
}
.graphLineRow {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	width: 100%;
}
.graphLineTitle {
	font-size: 12px;
	font-weight: bold;
	color: #285a64;
	margin-right: 5px;
	width: 30px;
}
.graphLineRectangleContainer {
	height: 26px;
	flex: 1;
	border: solid 1px white;
	width: 100%;
	display: flex;
	position: relative;
}
.graphLineRectangle {
	background: #f1e0c4;
	height: 100%;
}
.graphLineText {
	margin-left: 5px;
}
.Page {
	display: flex;
	padding-top: 60px;
	margin-top: 0;
	position: relative;
	left: -85px;
	width: calc(100% + 85px);
	overflow-x: auto;
	overflow-y: hidden;
}
.PageColumn {
	width: 100%;
	width: 470px;
	margin-right: 30px;
	flex: 10 0 auto;
	padding-left: 85px;
}

.DetailsActionCard {
	flex: 10 0 auto;
	padding-left: 50px;
	padding-right: 50px;
}
body {
	overflow-x: hidden;
}
.ChipContainer {
	display: inline-block;
	margin: 0 5px;
	i {
		color: #d39c3e;
		cursor: pointer;
	}
}

.GraphModal {
	position: absolute;
	background: white;
	padding: 20px;
	border-radius: 20px;
	border: solid 1px #cccccc;
	top: 100px;
	left: 50px;
	width: calc(100% - 100px);
	i {
		color: #d39c3e;
		cursor: pointer;
	}
}
.Row {
	display: flex;
	justify-content: center;
}

.ButtonDisabled {
	opacity: 0.3;
	cursor: default;
}

.StatusCardSpace {
	display: flex;
	flex-direction: column;
	.Text {
		font-size: 20px;
		text-align: right;
		font-weight: bold;
	}
	.Description {
		font-size: 12px;
		margin-top: 3px;
	}
}
.StatusBlockContainer {
	height: 113px;
	display: flex;
	position: relative;
	left: -1px;
}
.StatusBlock {
	background: #f1e0c4;
	width: 26px;
	height: 100%;
	margin-right: 5px;
	border-right: solid 0.5px #cccccc;
	border-left: solid 0.5px #cccccc;
	cursor: pointer;
}

.TransactionCard {
	margin-bottom: 0;
	border: 0;
	width: 100%;
	white-space: nowrap;
}
.parentTransactionCard {
	display: flex;
	margin-bottom: 10px;
	border: solid 0.5px #cccccc;
	border-radius: 0 24px 24px 0;
}

.DetailsModal {
	position: absolute;
	width: 100%;
	height: 100%;
	background: white;
	top: 0;
	left: 0;
	padding: 85px;
	overflow-x: scroll;
}
.DetailsTitle {
	display: flex;
	align-items: center;
	.DetailsTitleText {
		font-size: 35px;
		font-weight: bold;
	}
	i {
		cursor: pointer;
	}
}
.CardsContainer {
	position: relative;
}
