.ModalItem {
	position: absolute;
	box-shadow: 0px 0px 5px 0px #444;
	border-radius: 0px 0px 20px 20px;
	font-weight: 500;
	z-index: 99;
	&::before {
		content: "";
		position: absolute;
		border-style: solid;
		border-width: 0 30px 30px 30px;
		border-color: transparent transparent #285a64 transparent;
	}
}
.ModalHeader {
	background: #285a64;
	height: 50px;
	color: white;
	display: flex;
	align-items: center;
	font-weight: bold;
	font-size: 20px;
	font-family: "Lato";
	padding-left: 15px;
}
.ModalBody {
	background: white;
	border-radius: 0px 0px 20px 20px;
	overflow: hidden;
}

.ModalHeaderIcon {
	margin-right: 15px;
}

////FORMS
.FilterItem {
	height: 100px;
}
.ItemHeader {
	background: #285a64;
	height: 35px;
	width: 100%;
	display: flex;
	align-items: center;
}
.ItemTitle {
	color: white;
	font-size: 15px;
	font-weight: bold;
	margin-top: 15px;
	margin-left: 10px;
}
.Currencies {
	display: flex;
	justify-content: center;
}
.Currency {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	width: 32px;
	height: 32px;
	margin: 10px 5px;
	padding: 5px;
	font-weight: bold;
	font-size: 20px;
	border-radius: 50%;
	border: 1px solid #285a64;
	cursor: pointer;
}

.Inputs {
	margin: 10px 5px;
	color: #070707;
	width: 100%;
	outline: none;
	border: 0.5px solid #cccccc;
	background-color: white;
	font: inherit;
	//   padding: 6px 10px;
	padding: 0 6px;
	height: 33px;
	box-shadow: 0px 0px 4px 1px rgba(240, 240, 240, 1);
}

.Inputs::placeholder {
	color: #ccc;
	text-align: end;
}

// input,
// input::-webkit-input-placeholder {
//     font-size: 16px;
//     line-height: 3;
// }

.CenterButtons {
	display: flex;
	justify-content: center;
	margin: 0 10px;
}

.CurrencyDropdown {
	margin-top: 10px;
}

.SortIcon {
	color: rgb(211, 155, 62);
	position: absolute;
	margin-top: -7px;
	margin-left: -23px;
}
