@import "../../Global.module.scss";

.PenaltyPercentage {
	height: 100px;
	font-weight: bold;
	border: 0.5px solid #cccccc;
	border-radius: 38px;
	padding: 41px;
	box-sizing: border-box;
	margin-top: 20px;
}

.PenIcon {
	color: white;
	background: #d39c3e;
	padding: 2px;
	font-size: 14px;
	margin-right: 5px;
	border-radius: 3px;
}
