@import "../Global.module.scss";

.WelcomePage {
	background: white;
	text-align: -webkit-center;
	text-align: -moz-center;
	text-align: -o-center;
	text-align: -ms-center;
}

.WelcomeContent {
	display: flex;
	flex-direction: column;
	max-width: 1406px;
	padding-top: 80px;
}

.WelcomeText {
	font: Bold 25px/29px Lato;
	letter-spacing: 0.13px;
	align-self: baseline;
	margin: 10px;
}

.Navigation {
	display: flex;
	flex-wrap: wrap;
	font: Bold 25px/29px Lato;
	letter-spacing: 0.13px;
	margin-bottom: 20px;
}

.Navigation div {
	width: 244px;
	height: 200px;
	padding-top: 44px;
	margin-top: 35px;
	margin-right: 35px;
}

.Navigation div:hover {
	cursor: pointer;
}

.NewUsers {
	position: relative;
	top: -27px;
	left: 15px;
	color: #9a2020;
}
