.CardHeader {
	height: 55px;
	width: 100%;
	box-sizing: border-box;
	background: #285a64;
}

.CardHeaderText {
	display: flex;
	align-items: center;
	color: white;
	text-align: start;
	font: Bold 25px/29px Lato;
	letter-spacing: 0.13px;
	padding-left: 10px;
}

.Button {
	border: none;
	outline: none;
	cursor: pointer;
	font: Bold 16px/19px Open Sans;
	border-radius: 12px;
	width: 100px;
	height: 39px;
	font-size: 16px;
	font-family: "Open Sans";
}

.YellowButton {
	@extend .Button;
	background: #d39c3e 0% 0% no-repeat padding-box;
	color: white;
}

.WhiteButton {
	@extend .Button;
	width: 101px;
	background: #ffffff 0% 0% no-repeat padding-box;
	color: #d39c3e;
	border: 1px solid #d39c3e;
}

.RedButton {
	@extend .Button;
	background: #9a2020 0% 0% no-repeat padding-box;
	color: white;
}

.NavItems {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border: 0.5px solid #cccccc;
	border-radius: 38px;
}

/////FORMS
.TextInput {
	color: #285a64;
	outline: none;
	border: 0.5px solid #cccccc;
	background-color: white;
	font: inherit;
	// padding: 6px 10px;
	text-indent: 9px;
	height: 33px;
	// box-shadow: 0px 0px 4px 1px rgba(240,240,240,1);
}

.LowOpacity {
	opacity: 0.3;
}

.TextInput::placeholder {
	text-align: start;
	color: #ccc;
}

//Pages
//TradingBook, CashManager, CurrencyTrader
.Page {
	display: flex;
	margin-top: 60px;

	.LinkIcon {
		font-size: 21px;
		height: 17px;
		text-align: center;
		// margin-left: 90px;
		color: #d39b3e;
	}
}

.PageColumn {
	width: 100%;
	max-width: 593px;
	margin-right: 30px;
	.Title {
		display: flex;
		align-items: center;
		span {
			font: Bold 35px/9px Open Sans;
		}
	}
}

//Transaction Card
.TransactionCard {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 85px;
	border: 0.5px solid #cccccc;
	border-radius: 0 24px 24px 0;
	border-left: 26px solid #285a64;
	padding: 15px 37px 13px 10px;
	margin-bottom: 10px;
	font-weight: bold;
	cursor: pointer;
	.UserIcon {
		font-size: 13px;
		// cursor: pointer;
		vertical-align: baseline;
	}

	.FirstRow {
		font-size: 12px;
		display: flex;
		align-items: flex-end;
	}
}

//Pagination
.order_pagination {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.numbers {
	margin-right: 5px;
	cursor: pointer;
	font-size: 15px;
	color: #d39c3e;
	font-weight: bold;
}

ul {
	list-style-type: none;
	display: flex;
}

//Confirmation Dialog
.CustomConfirmationDialog {
	color: #285a64;
	font-family: "Open Sans";
	background: white;
	border: 0.5px solid rgb(204, 204, 204);
	border-radius: 24px;
	padding: 26px;
	height: 100px;
}

.activePage {
	color: #a27930 !important;
	font-size: 20px !important;
}

*,
::after,
::before {
	box-sizing: unset;
}
