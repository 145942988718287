.SearchBar {
	margin-top: 20px;
}

.SearchBar i {
	flex: 1;
	padding-top: 4px;
}

.SearchIcon {
	color: #cccccc;
	margin: 11px 0 0 7px;
}

.SearchInput {
	border: 0.5px solid #cccccc;
	height: 35px;
	width: 100%;
	max-width: 528px;
	text-indent: 33px;
	&:focus {
		outline: none;
	}
}

.SearchItems {
	flex-direction: column;
	align-items: flex-start;
	margin-top: 12px;
	font-size: 18px;
	max-height: 450px;
	overflow: auto;
}

.SelectionCircle {
	width: 23px;
	height: 23px;
	border: 2px solid #d39c3e;
	border-radius: 50%;
}

.Item {
	margin: 10px;
	cursor: pointer;
	display: flex;
	width: 98%;
}
