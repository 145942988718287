@import "../../../Global.module.scss";
@import "../Modals.module.scss";

.FilterModal {
	top: 54px;
	width: 341px;
	left: -254px;
	&::before {
		top: -30px;
		left: 248px;
	}
	margin-bottom: 20px;
	font-weight: 500;
}

.FilterModalBody {
	display: flex;
	flex-flow: column;
	justify-content: center;
	padding: 20px 40px 40px;
}

.FilterModalHeader {
	padding-right: 216px;
}
.Currencies {
	flex-wrap: wrap;
}
.Currency {
	width: 25px;
	height: 25px;
}
.checkbox {
	margin-right: 10px;
	display: flex;
	font-weight: bold;
	cursor: pointer;
	div {
		margin-right: 5px;
		width: 20px;
		height: 20px;
		border: solid 1px;
		border-radius: 50%;
	}
	div.checked {
		background: #285a64;
	}
}
