@import "../../Global.module.scss";

.AccountsCard {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 80px;
	border: 0.5px solid #cccccc;
	border-radius: 24px;
	padding: 20px 37px 13px;
	margin-bottom: 10px;
	font-weight: bold;
	cursor: pointer;

	.UserIcon {
		font-size: 13px;
		cursor: pointer;
		vertical-align: baseline;
	}

	.Circle {
		border-radius: 50%;
		width: 18px;
		height: 18px;
		background: #649177; //#9A2020
		margin-right: 5px;
	}
}

.AccountDetails {
	width: 842px;
	height: auto;
	min-height: 752px;
	display: table;
	border: 0.5px solid #cccccc;
	border-radius: 24px;
	padding: 35px;
	margin-left: 5px;
	position: relative;
}

.Triangle {
	left: -30px;
	content: "";
	position: absolute;
	border-style: solid;
	border-width: 30px 30px 30px 0;
	border-color: transparent #c2d0d3 transparent transparent;
}

.Header {
	.Item1 {
		width: 842px;
		height: 28px;
		padding: 8px 0;
		text-align: center;
		font: Bold 20px/30px Lato;
		color: white;
		background: #285a64;
	}

	.Item2 {
		width: 421px;
		height: 28px;
		padding: 9px 0;
		text-align: center;
		font: Bold 20px/30px Lato;
		color: white;
		background: #285a64;
	}

	.ActiveStyle {
		color: #285a64;
		background: rgba($color: #285a64, $alpha: 0.3);
	}
}

.CurrencyTraderInfo {
	margin: 20px 15px;

	.Header {
		display: flex;
		align-items: center;
	}

	.Title {
		font-size: 24px;
		font-weight: bold;
	}

	.LeftSide {
		margin-left: auto;
		font-size: 14px;
		align-self: flex-end;
		padding-right: 20px;
	}
}

.PenIcon {
	color: white;
	background: #d39c3e;
	padding: 2px;
	font-size: 10px;
	margin-right: 5px;
	border-radius: 3px;
}

.CurrencyTraderTable {
	width: 98%;
	margin: 25px 0 20px;

	td {
		padding: 25px 0;
	}

	td:nth-child(1) {
		width: 12%;
	}

	td:nth-child(2) {
		width: 27%;
	}

	td:nth-child(-n + 2) {
		font-size: 24px;
		font-weight: bold;
	}
	td:nth-child(n + 3) {
		font-size: 12px;
	}
}

.UserInfo {
	margin: 30px 0;
	font-size: 14px;

	.Content {
		display: flex;
		margin-top: 30px;
	}

	.Section3 {
		display: flex;
		flex-direction: column;
		height: 345px;
		margin-right: 30px;
		margin-bottom: 30px;
	}

	.Section1 {
		@extend .Section3;
		justify-content: space-between;
	}

	.Section2 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.Section4 {
		@extend .Section2;
		align-items: center;
		height: 165px;
	}

	.Section5 {
		@extend .Section3;
		justify-content: space-around;
	}

	.SubSection {
		display: flex;
		flex-direction: column;
		height: 278px;
		justify-content: space-between;
	}

	.ActionButtons {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 100%;
		max-width: 220px;
		position: relative;
		bottom: 30px;
	}

	.Title {
		border-bottom: 0.5px solid #cccccc;
		padding-bottom: 5px;
	}

	.DisabledIcon {
		color: white;
		background: #9a2020;
		height: 31px;
		width: 36px;
		padding: 0px;
		padding-top: 5px;
		margin-right: 7px;
		border-radius: 4px;
		text-align: center;
		vertical-align: sub;
	}
}

.AccountingInfo {
	margin: 60px 10px 0;
	font-size: 14px;
	input[type="text"] {
		width: 140px;
	}

	.Title {
		border-bottom: 0.5px solid #cccccc;
		padding-bottom: 5px;
	}
}

.SpreadsTable {
	width: 56%;

	.Currency {
		width: 15%;
	}

	td:nth-child(n + 1) {
		text-align: center;
	}

	input {
		text-align: right;
		padding-right: 7px;
	}
}

.BankInfo {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

.GroupDetails {
	display: flex;
	justify-content: space-between;
	margin: 30px 0;

	.SubTitle {
		width: 151px;
		text-align: center;
	}
}

.PurchasesSpreads {
	display: flex;
	max-width: 151px;
	height: 161px;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: space-evenly;

	.TableRow {
		display: flex;
		width: 100%;
		justify-content: space-around;
	}
}

.AddGroup {
	width: 100%;
	max-width: 590px;
	.Title {
		display: flex;
		align-items: center;
		div {
			font: Bold 35px/9px Open Sans;
		}
	}
}

.SelectCircle {
	position: absolute;
	margin-left: -46px;
	margin-top: 36px;
	width: 29px;
	height: 29px;
	border-radius: 50%;
	border: 1px solid #cccccc;
}

.EditGroupIcon {
	float: right;
	margin-top: 4px;
	margin-right: 10px;
	color: white;
}

.GroupName {
	width: 450px;
	height: 33px;
	border: none;
	border-bottom: 0.5px solid #cccccc;
	// font-size: 35px;
	font: Bold 35px/9px Open Sans;
	color: #285a64;
	&::placeholder {
		opacity: 0.3;
		font-weight: 400;
	}
}
.GroupName:focus {
	outline: none;
}

//Shareholders popup
.Shareholders {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;

	.Container {
		height: 100%;
		max-height: 390px;
		width: 100%;
		max-width: 700px;
		padding: 45px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #cccccc;
		border-radius: 38px;
	}

	.ShareholdersTitle {
		font: Bold 35px/15px Open Sans;
	}
}
.ShareholdersContainer {
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
}
